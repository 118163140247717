import React from 'react';
import Player from './Player';
import { cn } from 'utils/cn';

type PlayerData = {
  rank: number;
  user: string;
  ore: number;
  imageSrc: string;
};

interface PodiumProps {
  players: PlayerData[];
}

const Podium: React.FC<PodiumProps> = ({ players }) => {
  if (players.length < 3) return <div>No data</div>;

  const podiumPositions: Array<{ size: string; order: number }> = [
    { size: 'rectangle-215', order: 1 },
    { size: 'rectangle-260', order: 2 },
    { size: 'rectangle-215', order: 3 },
  ];

  return (
    <div className="podium-wrapper">
      {[players[1], players[0], players[2]].map((player, index) => (
        <div
          key={player.rank}
          className="player-position"
          style={{
            order: podiumPositions[index].order,
          }}>
          <div className="player-wrapper">
            <Player
              rank={player.rank}
              user={player.user}
              ore={player.ore}
              imageSrc={player.imageSrc}
              isWinner={player.rank === 1}
            />
          </div>

          <div className="container">
            <div className={cn('rectangle', podiumPositions[index].size)}>
              <div className="face front">
                <div className="gradient-overlay"></div>
              </div>
              <div className="face top"></div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Podium;
