import React, { useState, useEffect, useRef } from 'react';

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const BottomSheet: React.FC<BottomSheetProps> = ({ isOpen, onClose, children }) => {
  const [isClosing, setIsClosing] = useState(false);
  const sheetRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isOpen) {
      setIsClosing(false);
    }
  }, [isOpen]);

  const handleOverlayClick = () => {
    setIsClosing(true);
    setTimeout(onClose, 300);
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    const touch = e.touches[0];
    const startY = touch.clientY;

    const handleTouchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY;
      const diff = currentY - startY;

      if (diff > 50) {
        setIsClosing(true);
        setTimeout(onClose, 300);
        window.removeEventListener('touchmove', handleTouchMove);
      }
    };

    window.addEventListener('touchmove', handleTouchMove);
  };

  return (
    <>
      <div
        className={`fixed inset-0 bg-dark-600 bg-opacity-50 transition-opacity duration-300 ${
          isOpen && !isClosing ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
        onClick={handleOverlayClick}
      />
      <div
        ref={sheetRef}
        className={`fixed bottom-0 left-0 right-0 bg-dark-600 rounded-t-2xl shadow-lg transform transition-transform duration-300 ease-in-out max-h-[80vh] overflow-y-auto ${
          isOpen && !isClosing ? 'translate-y-0' : 'translate-y-full'
        }`}
        onTouchStart={handleTouchStart}
      >
        {children}
      </div>
    </>
  );
};

export default BottomSheet;
