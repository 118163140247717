import { Pickaxe } from 'components/MyPickaxes';
import ArrowBack from 'assets/svg/arrow-back.svg';
import MoreIcon from 'assets/svg/more-icon.svg';
import FlameIcon from 'assets/svg/flame.svg';
import TriadLogo from 'assets/svg/triad.svg';
import CloseIcon from 'assets/svg/close.svg';
import React, { useState } from 'react';
import Dropdown from 'components/Dropdown';
import BottomSheet from 'components/BottomSheet';
import Button from 'components/Button';
import { cn } from 'utils/cn';

const PickAxeDetail = ({
  currentPickaxe,
  rowBack,
}: {
  currentPickaxe: Pickaxe;
  rowBack: () => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [burnChecked, setBurnChecked] = useState(false);

  const renderPickaxeInfo = () => (
    <div className="bg-dark-500 rounded-lg mt-5">
      {[
        {
          label: 'Description',
          value: 'Boost your mining by $ORE with Triad Pickax',
        },
        { label: 'Collection', value: 'Triad Pickax Collection' },
        { label: 'Preço de limiar', value: '1 ORE' },
        { label: 'Sole owners', value: currentPickaxe.owners },
        { label: 'Network', value: 'Solana' },
      ].map(({ label, value }, index, array) => (
        <div
          key={label}
          className={cn(
            'px-2.5 py-2 flex flex-wrap items-center justify-between',
            index !== array.length - 1 && 'border-b-[1px] border-dark-600',
          )}>
          <span className="text-dark-400 text-base flex items-center mb-1">
            {label}
          </span>
          <span className="text-white text-base">{value}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="w-full">
      <button onClick={() => rowBack()} className="flex items-center">
        <img width={40} height={40} src={ArrowBack} alt="arrow" />
      </button>

      <img
        src={currentPickaxe.image}
        alt={currentPickaxe.title}
        className="w-full h-[333px] object-contain mb-3"
      />

      <div className="flex justify-between items-start">
        <div>
          <h1 className="text-2xl font-bold mb-2 permanent-marker-regular text-white">
            Triad <span className="text-[#F2BE47]">Pickax</span>{' '}
            <span className="text-dark-400 ml-1 text-xl">
              {currentPickaxe.code}
            </span>
          </h1>
          <span className="uppercase text-dark-400 text-sm flex items-center">
            Created by{' '}
            <img
              src={TriadLogo}
              className="w-[49px] h-[19px] object-contain ml-1"
              alt="Triad"
            />
          </span>
        </div>
        <Dropdown
          options={[
            {
              content: (
                <span className="text-negative-500 flex items-center">
                  <img
                    className="mr-2"
                    width={22}
                    height={22}
                    src={FlameIcon}
                    alt="Flame Icon"
                  />{' '}
                  Burn NFT
                </span>
              ),
              action: () => setIsOpen(!isOpen),
            },
          ]}>
          <img width={46} height={46} src={MoreIcon} alt="arrow" />
        </Dropdown>
      </div>

      {renderPickaxeInfo()}

      <BottomSheet isOpen={isOpen} onClose={() => setIsOpen(!isOpen)}>
        <div className="pb-4 pt-2">
          <div className="flex items-center justify-between border-b border-dark-500 pb-2">
            <span>
              <img src={CloseIcon} className="size-10 object-contain ml-1" alt='Close' />
            </span>
            <span className="text-white">Burn NFT</span>
            <span className="size-10"></span>
          </div>
          <div className="px-4">
            <div>
              <div className="relative w-fit mx-auto mt-5">
                <img
                  src={currentPickaxe.image}
                  alt={currentPickaxe.title}
                  className="w-[141px] h-[130px] object-contain mb-3"
                />
                <div className="w-10 h-10 flex items-center justify-center bg-[#EE5F6733]/20 rounded-full absolute -bottom-2 -right-3">
                  <img width={22} height={22} src={FlameIcon} alt="" />
                </div>
              </div>
              <span className="text-base text-dark-400 mt-5 block">
                This action will permanently destroy and remove this NFT from
                your wallet.
              </span>
            </div>
            <div className="bg-dark-500 rounded-lg py-1 mt-1 mb-5">
              <div className="px-2.5 border-b-[1px] border-dark-600 py-1 flex items-center">
                <span className="text-dark-400 text-base flex items-center mb-1">
                  NFT{' '}
                </span>
                <span className="text-white text-base ml-auto"> #111</span>
              </div>
              <div className="px-2.5 border-b-[1px] border-dark-600 py-1 flex items-center">
                <span className="text-dark-400 text-base flex items-center mb-1">
                  Limiar Price
                </span>
                <span className="text-white text-base ml-auto"> 1 ORE</span>
              </div>
              <div className="px-2.5 pt-2 flex items-center">
                <span className="text-dark-400 text-base flex items-center mb-1">
                  You will receive
                </span>
                <span className="text-green-600 text-base ml-auto">
                  {' '}
                  +1 ORE
                </span>
              </div>
            </div>
            <span className="mb-12 block">
              <div className="flex items-center">
                <button
                  id="advert"
                  className={cn(
                    'size-4 rounded border border-gray-300 flex items-center justify-center focus:outline-none',
                    burnChecked && 'bg-primary border-primary',
                  )}
                  onClick={() => setBurnChecked(!burnChecked)}>
                  {burnChecked && (
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M5 13l4 4L19 7"></path>
                    </svg>
                  )}
                </button>
                <label className="ml-2 text-dark-400 text-sm" htmlFor="advert">
                  I understand that this action cannot be undone.
                </label>
              </div>
            </span>
            <div className="grid grid-cols-2 gap-x-5">
              <Button className="w-full border border-white/50 bg-white/10">
                Cancel
              </Button>
              <Button className="w-full bg-negative-500 !text-dark-600">
                Burn
              </Button>
            </div>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
};

export default PickAxeDetail;
