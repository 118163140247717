import React, { useState, useEffect, useMemo } from 'react';
import Podium from './Podium';
import LeaderboardTable from './LeaderboardTable';
import oreWhite from 'assets/svg/ore-white.svg';
import posseidonPurple from 'assets/svg/posseidon-purple.svg';
import poseidonBlue from 'assets/svg/posseidon-blue.svg';
import posseidonGolden from 'assets/svg/posseidon-golden.svg';

type LeaderboardData = {
  user: string;
  ore: number;
  imageSrc: string;
};

const Leaderboard: React.FC = () => {
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardData[]>([]);
  const [topPlayers, setTopPlayers] = useState<LeaderboardData[]>([]);

  const currentUser = useMemo(
    () => ({
      id: 123,
      rank: 0,
      name: 'Poseidon',
      ore: 0,
      img: posseidonGolden,
    }),
    [],
  );

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      const data: LeaderboardData[] = [
        { user: 'Poseidon 2', ore: 0.02, imageSrc: posseidonPurple },
        { user: 'Poseidon 3', ore: 0.1, imageSrc: poseidonBlue },
        { user: 'V.R.', ore: 0.0004, imageSrc: posseidonPurple },
        { user: 'Posseidoon 9', ore: 0.002, imageSrc: posseidonGolden },
        { user: 'V.R.', ore: 0.0004, imageSrc: poseidonBlue },
      ];

      const sortedData = [...data].sort((a, b) => b.ore - a.ore);

      setLeaderboardData(sortedData);

      setTopPlayers(sortedData.slice(0, 3));
    };
    fetchLeaderboardData();
  }, []);

  const topPlayersWithRank = topPlayers.map((player, index) => ({
    ...player,
    rank: index + 1,
  }));

  return (
    <div className="itim-regular text-sm md:text-base">
      <h2 className="permanent-marker-regular mb-6 mt-10 text-center text-2xl font-bold text-white sm:mb-0 sm:text-3xl">
        Leaderboard
      </h2>

      <Podium players={topPlayersWithRank} />

      <div className="mb-5 mt-5 flex h-[54px] rounded-lg bg-[#2E3038] px-6 text-white">
        <div className="flex w-full items-center justify-between">
          <div className="flex items-center">
            <span className="permanent-marker-regular w-8 flex-shrink-0 text-[#A1A7BB]">
              {currentUser.rank}
            </span>
            <div className="ml-5 flex items-center">
              <img src={currentUser.img} alt="" className="h-10 w-10" />
              <div className="ml-3 flex flex-col items-start font-bold">
                <span className="text-[#A1A7BB]">Me</span>
                <span className="text-sm">{currentUser.name}</span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-end justify-center">
            <span className="permanent-marker-regular text-xs font-bold text-[#A1A7BB]">
              ORE
            </span>
            <div className="flex items-center space-x-2">
              <img src={oreWhite} alt="ORE" width={14} height={14} />
              <span className="text-sm font-bold">{currentUser.ore}</span>
            </div>
          </div>
        </div>
      </div>

      <LeaderboardTable data={leaderboardData} />
    </div>
  );
};

export default Leaderboard;
