import OreMined from './ore-mined';
import Pickaxe from 'assets/img/pickaxe.gif';
import PickaxeStop from 'assets/img/pickaxe-stop.png';
import { useEffect } from 'react';
import { useTriad } from 'context/Triad';

const Mine: React.FC = () => {
  const { isMining, setIsMining, poolProof } = useTriad();

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <OreMined />

      <div className="h-[164px] mt-12 mb-14 flex items-center justify-center">
        {isMining ? (
          <img className="h-[260px]" src={Pickaxe} alt="" />
        ) : (
          <img className="h-[215px] -ml-6 -mt-2" src={PickaxeStop} alt="" />
        )}
      </div>
      <div>
        <div className="flex justify-between items-center rounded text-xs w-full text-white/80 py-3 px-3 border border-white/[0.12] bg-white/[0.01]  permanent-marker-regular">
          <span>Mining pool rewards</span>
          <span>{poolProof.total_rewards} ORE</span>
        </div>

        <div className="w-full flex items-center justify-between gap-x-3 mt-2.5 permanent-marker-regular">
          <button
            onClick={() => setIsMining((prev) => !prev)}
            className="rounded bg-primary text-white h-[50px] w-full">
            {isMining ? 'MINING $ORE ...' : 'MINE $ORE'}
          </button>

          {/* <button className="rounded bg-[#44454c] text-white h-[50px] w-1/2 border border-[#FFFFFF80]">
            CLAIM $ORE
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Mine;
