'use client';

import { useCallback, useEffect } from 'react';
import type { ReactNode } from 'react';
import { cn } from 'utils/cn';

export type ModalProps = {
  children: ReactNode;
  onClose: (value: boolean) => void;
  className?: {
    modal?: string;
  };
};

export function Modal({ children, onClose, className }: ModalProps) {
  const handleCloseEscKey = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose(false);
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    document.addEventListener('keydown', handleCloseEscKey);

    return () => {
      document.removeEventListener('keydown', handleCloseEscKey);
      document.body.style.overflow = 'auto';
    };
  }, [handleCloseEscKey]);

  return (
    <>
      <div
        onClick={() => onClose(false)}
        className={cn(
          'fixed left-0 top-0 z-[99] flex size-full animate-fade cursor-pointer items-center bg-black/10 animate-duration-[400ms] animate-once animate-ease-linear',
        )}
      />

      <div
        className={cn(
          'fixed z-[999] mt-6 lg:m-auto h-fit w-full animate-fade-up animate-duration-300 animate-once animate-ease-linear',
          className?.modal,
        )}>
        <div className="h-auto w-full overflow-y-auto">{children}</div>
      </div>
    </>
  );
}
