'use client';

import { Magic } from 'magic-sdk';
import { OAuthExtension } from '@magic-ext/oauth';
import { SolanaExtension } from '@magic-ext/solana';
import { MAGIC_API_KEY, RPC_URL } from './contants';

const magicInstance = () => {
  return new Magic(MAGIC_API_KEY, {
    extensions: [
      new OAuthExtension(),
      new SolanaExtension({
        rpcUrl: RPC_URL,
      }),
    ],
  });
};

export const magic = magicInstance();
