import React, { useState } from 'react';
import PickaxeImg from 'assets/img/pickaxe.png';
import Button from 'components/Button';
import EmptyPickax from 'assets/svg/pickaxe-empty.svg';
import PickAxeDetail from 'components/PickAxeDetail';

export interface Pickaxe {
  title: string;
  description: string;
  code: string;
  collection: string;
  price: string;
  owners: string;
  network: string;
  image: string;
}

const MyPickaxes = () => {
  const [currentPickaxe, setCurrentPickaxe] = useState<Pickaxe | null>(null);
  const pickAxes: {
    title: string;
    description: string;
    code: string;
    collection: string;
    price: string;
    owners: string;
    network: string;
    image: string;
  }[] = [];

  if (pickAxes.length === 0) {
    return (
      <div className="h-full flex flex-col items-center justify-center">
        <img className="w-[110px] h-[135px] mx-auto" src={EmptyPickax} alt="" />
        <span className=" text-white/80 text-xs text-center mt-[30px]">
          You don't have any NFTS
        </span>
        <Button
          onClick={() => {
            window.open('https://pickax.triadfi.co/', '_blank');
          }}
          className="mt-[86px] max-w-[161px]">
          Mint + Pickax
        </Button>
      </div>
    );
  }

  if (currentPickaxe) {
    return (
      <PickAxeDetail
        currentPickaxe={currentPickaxe}
        rowBack={() => setCurrentPickaxe(null)}
      />
    );
  }

  return (
    <div className="flex flex-col w-full">
      <span className="text-white permanent-marker-regular text-2xl text-left">
        Explore your nfts <br />{' '}
        <span className="text-[#F2BE47] text-3xl">TRIAD PICKAXES</span>
      </span>
      <div className="grid grid-cols-2 gap-2 mt-5">
        {pickAxes.map((pickaxe, key) => (
          <div
            onClick={() => setCurrentPickaxe(pickaxe)}
            className="relative"
            key={key}>
            <img src={PickaxeImg} alt="Pickaxe" className="w-full" />
            <div className="w-11/12 text-center py-1 rounded-[4px] absolute bottom-2 left-0 right-0 mx-auto bg-white/10 text-base text-white permanent-marker-regular">
              Triad Pickax #111
            </div>
          </div>
        ))}
      </div>
      <Button
        onClick={() => {
          window.open('https://pickax.triadfi.co/', '_blank');
        }}
        className="mt-[86px] max-w-[161px] mx-auto">
        Mint + Pickax
      </Button>
    </div>
  );
};

export default MyPickaxes;
