import TriadLogo from 'assets/svg/triad.svg';
import OreLogo from 'assets/svg/ore-full-logo.svg';
import GoogleLogo from 'assets/svg/google.svg';
import AppleLogo from 'assets/svg/apple.svg';
import { useUser } from 'context/User';

const Login: React.FC = () => {
  const { handleLogin } = useUser();

  return (
    <div className="w-full h-full roboto flex items-center flex-col justify-between pt-[110px]">
      <div>
        <div className="w-full flex items-center justify-center pt-6 mb-6">
          <img className="w-[114px] h-[44px]" src={TriadLogo} alt="" />
          <div className="w-[1px] h-6 bg-[#FFFFFF29] mx-6" />
          <img className="w-[100px] h-[34px]" src={OreLogo} alt="" />
        </div>
        <span className="text-white w-fit mx-auto">
          Boost Your Daily Mining Gains with $ORE
        </span>
      </div>

      <div className="w-full flex flex-col items-center">
        <button
          onClick={() => handleLogin('google')}
          className="w-full mb-2.5 gap-x-2.5 rounded bg-white text-black flex items-center justify-center h-11">
          <img src={GoogleLogo} alt="" /> Sign In with Google
        </button>
        <button
          onClick={() => handleLogin('apple')}
          className="w-full gap-x-2.5 rounded bg-black text-white flex items-center justify-center h-11">
          <img src={AppleLogo} alt="" /> Sign In with Apple
        </button>
      </div>
    </div>
  );
};

export default Login;
