import { PublicKey } from '@solana/web3.js';
import axios from 'axios';
import { useUser } from 'context/User';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ReactNode } from 'react';
import init, { fetch_logical_processors } from './../../utils/dioxus/ORE';

type TriadProviderProps = {
  children: ReactNode;
};

export type ContextValue = {
  verifySolanaAddress: (walletRecipient: string) => Promise<void>;
  isValidWallet: boolean | null;
  createMineAccount: () => Promise<void>;
  hasUserVerify: () => Promise<boolean | undefined>;
  isMining: boolean;
  setIsMining: React.Dispatch<React.SetStateAction<boolean>>;
  poolProof: {
    balance: number;
    last_hash_at: number;
    last_stake_at: number;
    total_hashes: number;
    total_rewards: number;
  };
  orePrice: number;
};

export const TriadContext = createContext<ContextValue | undefined>(undefined);

export function TriadProvider({ children, ...rest }: TriadProviderProps) {
  const { userWallet } = useUser();
  const [isMining, setIsMining] = useState(false);
  const [isValidWallet, setIsValidWallet] = useState<null | boolean>(null);
  const [poolProof, setPoolProof] = useState<{
    balance: number;
    last_hash_at: number;
    last_stake_at: number;
    total_hashes: number;
    total_rewards: number;
  }>({
    balance: 0,
    last_hash_at: 0,
    last_stake_at: 0,
    total_hashes: 0,
    total_rewards: 0,
  });
  const [orePrice, setOrePrice] = useState(0);
  const [workers, setWorkers] = useState<Worker[]>([]);
  const [challenge, setChallenge] = useState<Uint8Array>(new Uint8Array());

  const getOrePrice = useCallback(async () => {
    const response = await axios.get(
      'https://birdeye-proxy.jup.ag/defi/multi_price?list_address=oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp',
    );

    setOrePrice(
      response.data.data['oreoU2P8bN6jkk3jbaiVxYnG1dCXcYxwhwyK9jSybcp'].value,
    );
  }, []);

  useEffect(() => {
    getOrePrice();
  }, [getOrePrice]);

  const getPoolProof = useCallback(async () => {
    const response = await axios.get('https://api.triadfi.co/ore/proof');

    setPoolProof(response.data);
  }, []);

  useEffect(() => {
    getPoolProof();
  }, [getPoolProof]);

  const initWorkers = useCallback(async (newChallenge: Uint8Array) => {
    const clonedWorkers = [];

    setWorkers((prev) => {
      prev.forEach((i) => {
        i.terminate();
      });

      return [];
    });

    console.log('NEW CHALLENGE');

    console.log('CORES:', navigator.hardwareConcurrency);

    for (let i = 0; i < navigator.hardwareConcurrency; i++) {
      const worker = new Worker(
        new URL('./../../utils/worker.js', import.meta.url),
      );

      worker.onmessage = async (event) => {
        console.log('worker message', event.data);

        try {
          if (event.data.difficulty < 15) return;

          setWorkers((prev) => {
            prev.forEach((i) => {
              i.terminate();
            });

            return [];
          });

          await axios.post('https://api.triadfi.co/ore', {
            challenge: event.data.challenge,
            diff: event.data.difficulty,
            digest: event.data.digest,
            nonce: event.data.nonce,
            userWalletAddress: userWallet?.publicKey?.toBase58(),
          });
        } catch {}
      };

      clonedWorkers.push(worker);
    }

    setWorkers(clonedWorkers);

    console.log('workers', clonedWorkers);
    console.log('newChallenge', newChallenge);

    await new Promise((resolve) => setTimeout(resolve, 1000));

    for (let i = 0; i < clonedWorkers.length; i++) {
      const worker = clonedWorkers[i];

      worker.postMessage({
        challenge: newChallenge,
        cutoff_time: 0,
        worker_id: i,
        cores: navigator.hardwareConcurrency,
      });
    }
  }, []);

  const getChallenge = useCallback(async () => {
    const response = await axios.get('https://api.triadfi.co/ore/challenge');

    if (!response.data.data) return;

    let unitChallenge = new Uint8Array(response.data.data);

    setChallenge((prev) => {
      if (prev.toString() === unitChallenge.toString()) return prev;

      initWorkers(unitChallenge);

      return unitChallenge;
    });
  }, [initWorkers]);

  useEffect(() => {
    if (!isMining) return;

    getChallenge();

    const interval = setInterval(() => {
      getChallenge();
      getPoolProof();
    }, 20000);

    if (!isMining) clearInterval(interval);

    return () => clearInterval(interval);
  }, [isMining, getChallenge, getPoolProof]);

  const hasUserVerify = useCallback(async () => {
    if (!userWallet?.publicKey) return false;

    try {
    } catch {}
  }, [userWallet?.publicKey]);

  const createMineAccount = useCallback(async () => {
    if (!userWallet?.publicKey) return;
  }, [userWallet?.publicKey]);

  const verifySolanaAddress = useCallback(async (walletRecipient: string) => {
    try {
      if (!walletRecipient) {
        setIsValidWallet(null);
        return;
      }

      const recipientPubKey = new PublicKey(walletRecipient);

      const response = PublicKey.isOnCurve(recipientPubKey);

      setIsValidWallet(response);
    } catch {
      setIsValidWallet(false);
    }
  }, []);

  const value = useMemo(
    () => ({
      verifySolanaAddress,
      isValidWallet,
      createMineAccount,
      hasUserVerify,
      isMining,
      setIsMining,
      poolProof,
      orePrice,
      challenge,
    }),
    [
      verifySolanaAddress,
      isValidWallet,
      createMineAccount,
      hasUserVerify,
      isMining,
      setIsMining,
      poolProof,
      orePrice,
      challenge,
    ],
  );

  return (
    <TriadContext.Provider value={value} {...rest}>
      {children}
    </TriadContext.Provider>
  );
}

export const useTriad = (): ContextValue => {
  const context = useContext(TriadContext);

  if (context === undefined) {
    throw new Error('useTriad must be used within an TriadProvider');
  }

  return context;
};
