import Widget from 'components/Widget';

const Telegram = () => {
  return (
    <div className="w-full flex items-center justify-center h-screen bg-dark-600">
      <Widget isAppWidget />
    </div>
  );
};

export default Telegram;
