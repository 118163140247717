import React from 'react';
import oreWhite from 'assets/svg/ore-white.svg';
import crown from 'assets/svg/crown.svg';
import hammer from 'assets/svg/hammer.svg';

type PlayerProps = {
  rank: number;
  user: string;
  ore: number;
  imageSrc: string;
  isWinner?: boolean;
};

const Player: React.FC<PlayerProps> = ({
  rank,
  user,
  ore,
  imageSrc,
  isWinner,
}) => {
  const rankColorClass =
    rank === 1
      ? 'text-[#F4C154]'
      : rank === 2
      ? 'text-[#B1C1D9]'
      : rank === 3
      ? 'text-[#E3904C]'
      : 'text-white';

  const hammerBgColor =
    rank === 1
      ? 'bg-[#523B10]'
      : rank === 2
      ? 'bg-[#292F3B]'
      : rank === 3
      ? 'bg-[#523B10]'
      : '';
  const hammerBorderColor =
    rank === 1
      ? 'border-yellow-500'
      : rank === 2
      ? 'border-gray-300'
      : rank === 3
      ? 'border-yellow-900'
      : 'border-transparent';

  return (
    <div className="flex flex-col items-center">
      <div className="relative">
        <img src={imageSrc} alt={user} className="h-11 w-11" />
        {isWinner && (
          <img
            src={crown}
            alt="Winner"
            className="absolute -right-3 -top-3 h-6 w-6"
          />
        )}
      </div>

      <h3 className="mb-3 text-white">{user}</h3>

      {rank <= 3 && (
        <div
          className={`transform rounded-full w-[24px] h-[24px] flex items-center justify-center ${hammerBgColor} border-2 ${hammerBorderColor}`}>
          <img src={hammer} alt="Hammer" width={14} height={14} />
        </div>
      )}

      <div className="lg:mt-1 flex items-center rounded-lg bg-[#ffffff10] p-1">
        <span
          className={`permanent-marker-regular  text-[12px] ${rankColorClass}`}>
          #{rank}
        </span>
      </div>

      <div className="lg:mt-1 flex items-center">
        <img src={oreWhite} alt="ORE" width={14} height={14} />
        <span className="ml-1 text-white">{ore}</span>
      </div>
    </div>
  );
};

export default Player;
