import React from 'react';
import oreWhite from 'assets/svg/ore-white.svg';

type LeaderboardData = {
  user: string;
  ore: number;
};

type LeaderboardTableProps = {
  data: LeaderboardData[];
};

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ data }) => {
  return (
    <div className="">
      {/* Table Headers */}
      <div className="grid h-[46px] grid-cols-3 items-center border-b border-t border-[#2E3038] px-6">
        <div>
          <span className="font-bold text-[#A1A7BB]">Rank</span>
        </div>
        <div>
          <span className="font-bold text-[#A1A7BB]">User</span>
        </div>
        <div className="flex items-center justify-end">
          <span className="font-bold text-[#A1A7BB]">ORE</span>
        </div>
      </div>

      {/* Leaderboard Data */}
      <div className="space-y-3">
        {data.map((item, index) => (
          <div
            key={index}
            className="grid grid-cols-3 items-center rounded-lg px-6"
            style={{ lineHeight: '50px' }}>
            <div className="flex items-center">
              <span className="permanent-marker-regular font-bold text-white">
                {index + 1}
              </span>
            </div>
            <div>
              <span className="font-bold text-white">{item.user}</span>
            </div>
            <div className="flex items-center justify-end space-x-2">
              <img src={oreWhite} alt="ORE" width={14} height={14} />
              <span className="font-bold text-white">{item.ore}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LeaderboardTable;
