import AppProvider from 'context';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from 'pages/home';
import Telegram from 'pages/telegram';

const App: React.FC = () => {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/telegram" element={<Telegram />}></Route>
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
};

export default App;
