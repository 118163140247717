import orelogo from 'assets/img/ore.png';
import triadLogo from 'assets/svg/triad-icon.svg';
import { useTriad } from 'context/Triad';
import { useMemo } from 'react';

const OreMined: React.FC = () => {
  const { poolProof } = useTriad();

  const totalRewards = useMemo(() => {
    return Number(poolProof.total_rewards / 400);
  }, [poolProof.total_rewards]);

  return (
    <div className="w-full border border-[#FFFFFF1F] rounded-lg p-2">
      <div className="bg-[#FFFFFF1F] h-[88px] flex items-center px-3 py-4 rounded-md w-[full] relative">
        <img className="size-[56px]" src={orelogo} alt="" />
        <div className="pl-3 flex flex-col  permanent-marker-regular">
          <span className="text-[#A1A7BB] text-sm">You Mined</span>
          <span className="text-white text-xl">
            {totalRewards.toFixed(9)} ORE
          </span>
          {/* <span className="text-[#A1A7BB] text-sm">
            ${totalRewards / Number(orePrice.toFixed(2))}
          </span> */}
        </div>
        <div className="size-[30px] bg-dark-600 flex items-center justify-center rounded-tr-md rounded-bl-md absolute right-0 top-0">
          <img width={18} height={18} src={triadLogo} alt="triad" />
        </div>
      </div>
    </div>
  );
};

export default OreMined;
