import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import type { ReactNode } from 'react';
import { OAuthRedirectResult, WalletProps } from './types';
import { PublicKey } from '@solana/web3.js';
import { magic } from '../../utils/magic';

type UserProviderProps = {
  children: ReactNode;
};

export type WidgetStep =
  | 'Login'
  | 'Mine'
  | 'Account'
  | 'My Pickaxes'
  | 'Leaderboard';

type LoginProvider = 'google' | 'apple' | 'discord';

export type ContextValue = {
  userMagic: OAuthRedirectResult | null;
  handleLogin: (provider: LoginProvider) => Promise<void>;
  userWallet: WalletProps | null;
  currentStep: WidgetStep;
  setCurrentStep: (value: WidgetStep) => void;
};

export const UserContext = createContext<ContextValue | undefined>(undefined);

export function UserProvider({ children, ...rest }: UserProviderProps) {
  const [currentStep, setCurrentStep] = useState<WidgetStep>('Login');
  const [userMagic, setUserMagic] = useState<OAuthRedirectResult | null>(null);

  const handleLogin = useCallback(async (provider: LoginProvider) => {
    if (!magic) return;

    try {
      await magic?.oauth.loginWithRedirect({
        provider,
        redirectURI: typeof window !== 'undefined' ? window.location.href : '',
      });
    } catch {}
  }, []);

  const userWallet: WalletProps | null = useMemo(() => {
    if (!userMagic?.magic?.userMetadata?.publicAddress || !magic) return null;

    return {
      publicKey: new PublicKey(userMagic?.magic?.userMetadata?.publicAddress),
      signAllTransactions: magic?.solana?.signTransaction,
      signTransaction: magic?.solana?.signTransaction,
      signMessage: magic?.solana?.signMessage,
    } as WalletProps;
  }, [userMagic]);

  useEffect(() => {
    const user = localStorage.getItem('user-magic');

    try {
      const parsedUser = user ? JSON.parse(user) : null;

      if (parsedUser && parsedUser.magic && parsedUser.magic.userMetadata) {
        setCurrentStep('Mine');
        setUserMagic(parsedUser);
      }
    } catch (error) {
      console.error(error);
      localStorage.removeItem('user-magic');
    }
  }, []);

  useEffect(() => {
    if (!magic) return;

    magic.oauth
      .getRedirectResult()
      .then((res: OAuthRedirectResult) => {
        if (
          res &&
          res.magic &&
          res.magic.userMetadata &&
          res.magic.userMetadata.publicAddress
        ) {
          setUserMagic(res);
          setCurrentStep('Mine');
          localStorage.setItem('user-magic', JSON.stringify(res));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const value = useMemo(
    () => ({
      userMagic,
      handleLogin,
      userWallet,
      currentStep,
      setCurrentStep,
    }),
    [userMagic, handleLogin, userWallet, currentStep, setCurrentStep],
  );

  return (
    <UserContext.Provider value={value} {...rest}>
      {children}
    </UserContext.Provider>
  );
}

export const useUser = (): ContextValue => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUser must be used within an UserProvider');
  }

  return context;
};
